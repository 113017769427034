import React, { useCallback, useState } from 'react';
import { createResourceLocatorString, pathByRouteName } from '../../../../util/routes';
import routeConfiguration from '../../../../routing/routeConfiguration';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { FormattedMessage } from '../../../../util/reactIntl';
import IconArrowRight from '../../../../components/IconArrowRight/IconArrowRight';

import Drawer from 'react-modern-drawer';
import './TopDrawer.css';
import { useDispatch, useSelector } from 'react-redux';
import { logout, selectIsAuthenticated } from '../../../../ducks/auth.duck';
import { AvatarMedium, H4, InlineTextButton, NamedLink } from '../../../../components';

import css from '../TopbarDesktop/TopbarDesktop.module.css';
import { selectCurrentUser } from '../../../../ducks/user.duck';

const DrawerData = ({
  handleRedirect,
  history,
  currentUser,
  displayName,
  isAuthenticated,
  onLogout,
  inboxTab,
}) => {
  // State to track expanded categories
  const [expandedCategory, setExpandedCategory] = useState(null);

  // Toggle function to expand or collapse categories
  const toggleCategory = categoryLevel => {
    setExpandedCategory(prevCategory => (prevCategory === categoryLevel ? null : categoryLevel));
  };

  return (
    <>
      {isAuthenticated ? (
        <div>
          <AvatarMedium className={css.avatar} user={currentUser} />
          <span className={css.greeting}>
            <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
          </span>
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <FormattedMessage id="TopbarMobileMenu.logoutLink" />
          </InlineTextButton>
        </div>
      ) : null}
      <div className={css.browseMenu}>
        <h2>
          <FormattedMessage id="DrawerData.browseNewOwner" defaultMessage="Browse New Owner" />
        </h2>
        <div className={css.links}>
          {[
            {
              id: null,
              key: 'school_uniforms_academic_uni',
              heading: 'allSchoolUniforms',
              categoryLevel: 'allSchoolUniforms',
            },
            {
              id: 'academicUniform',
              key: 'school_uniforms_academic_uni',
              categoryLevel: 'allSchoolUniforms',
            },
            {
              id: 'sportsUniform',
              key: 'school_uniforms_sporting_uni',
              categoryLevel: 'allSchoolUniforms',
            },
            {
              id: 'peUniform',
              key: 'school_uniforms_cocurricular_uni',
              categoryLevel: 'allSchoolUniforms',
            },
            {
              id: 'textbooks',
              key: 'school_books_textbooks',
              heading: 'textbooksReadingBooks',
              categoryLevel: 'textbooks',
            },
            { id: 'readingBooks', key: 'school_books_reading_books', categoryLevel: 'textbooks' },
            {
              id: 'educationalBooks',
              key: 'school_books_educational_books',
              categoryLevel: 'textbooks',
            },
            {
              id: null,
              key: 'rec_items',
              heading: 'allrecItems',
              categoryLevel: 'technologyStationery',
            },
            {
              id: 'schoolFormal',
              key: 'rec_items_school_formal',
              categoryLevel: 'technologyStationery',
            },
            {
              id: 'bookWeek',
              key: 'rec_items_book_week',
              categoryLevel: 'technologyStationery',
            },
            {
              id: 'musicInstrument',
              key: 'rec_items_music_instruments',
              categoryLevel: 'technologyStationery',
            },
            {
              id: 'danceGymnastics',
              key: 'rec_items_dance_gymnastics',
              categoryLevel: 'technologyStationery',
            },
            {
              id: 'clubUniforms',
              key: 'rec_items_club_uniforms',
              categoryLevel: 'technologyStationery',
            },
            {
              id: 'skiCampGear',
              key: 'rec_items_ski_camp_gear',
              categoryLevel: 'technologyStationery',
            },
            {
              id: 'aquaticGear',
              key: 'rec_items_aquatic_gear',
              categoryLevel: 'technologyStationery',
            },
            {
              id: 'otherRecItems',
              key: 'other_rec_items',
              categoryLevel: 'technologyStationery',
            },
          ].map(item => (
            <div key={item?.key + item?.id}>
              {item.heading ? (
                <H4 onClick={() => toggleCategory(item.categoryLevel)}>
                  <FormattedMessage id={`DrawerData.${item.heading}`} defaultMessage={item.id} />
                </H4>
              ) : null}
              {expandedCategory === item.categoryLevel && item.id && (
                <div className={css.linkItem}>
                  <span onClick={() => handleRedirect(item.key, item.isListingType)}>
                    <FormattedMessage id={`DrawerData.${item.id}`} defaultMessage={item.id} />{' '}
                    <IconArrowRight />
                  </span>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div
        className={css.moreFrom}
        role="button"
        onClick={() =>
          history.push(createResourceLocatorString('NewListingPage', routeConfiguration(), {}, {}))
        }
      >
        <h2>
          <FormattedMessage id="DrawerData.sellItem" defaultMessage="Sell an item" />
        </h2>
      </div>
      <NamedLink className={css.link} name="InboxPage" params={{ tab: inboxTab }}>
        <FormattedMessage id="TopbarMobileMenu.inboxLink" />
      </NamedLink>
      <div className={css.moreFrom}>
        <h2>
          <FormattedMessage id="DrawerData.moreFromNewOwner" defaultMessage="More from New Owner" />
        </h2>

        <div className={css.links}>
          {[
            { id: 'howToDonate', pageId: 'how_to_donate' },
            { id: 'howToBuy', pageId: 'how_to_buy' },
            { id: 'howToSell', pageId: 'how_to_sell' },
            { id: 'ourStory', pageId: 'about' },
            { id: 'termsOfService', pageId: 'terms-of-use' },
            { id: 'privacyPolicy', pageId: 'privacy-policy' },
          ].map(item => (
            <div key={item.id} className={css.linkItem}>
              <NamedLink
                name={item.name || 'CMSPage'}
                className={css.link}
                params={{ pageId: item.pageId }}
              >
                <FormattedMessage id={`DrawerData.${item.id}`} defaultMessage={item.id} />
              </NamedLink>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

function TopDrawer({ isOpen, setIsOpen }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const currentUser = useSelector(selectCurrentUser);
  const { currentUserHasListings } = useSelector(state => state.user);
  const inboxTab = currentUserHasListings ? 'sales' : 'orders';

  const handleRedirect = useCallback(
    (key, isListingType = false) => {
      const searchQuery = isListingType ? { pub_listingType: key } : { pub_subcategory: key };
      setIsOpen(false);
      history.push(
        createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchQuery)
      );
    },
    [history, setIsOpen]
  );

  const displayName = currentUser?.attributes?.profile?.firstName;

  const onLogout = useCallback(() => {
    dispatch(logout()).then(() => {
      const path = pathByRouteName('LandingPage', routeConfiguration());

      // In production we ensure that data is really lost,
      // but in development mode we use stored values for debugging
      if (typeof window !== 'undefined') {
        window.location = path;
      }

      console.log('logged out'); // eslint-disable-line
    });
  }, []);

  return (
    <Drawer
      open={isOpen}
      onClose={() => setIsOpen(!isOpen)}
      direction="left"
      size="600px"
      className={css.desktopDrawerMenu}
    >
      <div className={css.drawerHead}>
        <span className={css.cross} onClick={() => setIsOpen(!isOpen)}>
          &times;
        </span>
      </div>
      {!isAuthenticated && (
        <div className={css.links}>
          <NamedLink name="SignupPage" className={css.linkItem}>
            <FormattedMessage id="TopbarMobileMenu.signupLink" />
          </NamedLink>
          <NamedLink name="LoginPage" className={css.linkItem}>
            <FormattedMessage id="TopbarMobileMenu.loginLink" />
          </NamedLink>
        </div>
      )}
      <DrawerData
        handleRedirect={handleRedirect}
        history={history}
        currentUser={currentUser}
        displayName={displayName}
        onLogout={onLogout}
        isAuthenticated={isAuthenticated}
        inboxTab={inboxTab}
      />
    </Drawer>
  );
}

export default TopDrawer;
